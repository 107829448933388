<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12" xl="4" md="6" class="mb-2">
        <div class="input-group">
          <span class="input-group-text input-group-text--addon-left">
            <b-icon icon="search"></b-icon>
          </span>
          <input
            v-model="filters.name"
            type="text"
            class="form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px"
            placeholder="搜索產品名稱"
          >
        </div>
      </b-col>
      <b-col cols="12" xl="2" md="6" class="mb-2">
        <router-link
          to="product/create"
          class="btn btn-secondary btn--h46px justify-content-center w-100"
        >
          新創建
        </router-link>
      </b-col>
      <b-col cols="12" xl="3" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-start"
          v-model="filters.start"
          class="b-form--datepicker form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="2021 年 11 月 10 日"
        ></b-form-datepicker>
      </b-col>
      <b-col cols="12" xl="3" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-end"
          v-model="filters.end"
          class="b-form--datepicker form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="2021 年 11 月 10 日"
        ></b-form-datepicker>
      </b-col>
    </b-row>

    <div class="card table-responsive border-0 shadow mb-4">
      <table class="table table-hover">
        <thead class="bg-brown--light">
          <tr>
            <th class="text-center text-nowrap"></th>
            <th class="text-center text-nowrap">排序序號</th>
            <th class="text-center text-nowrap">產品編號</th>
            <th class="text-center text-nowrap">預覽產品相片</th>
            <th class="text-center text-nowrap">產品名稱</th>
            <th class="text-center text-nowrap">产品名称(简体版本)</th>
            <th class="text-center text-nowrap">產品描述</th>
            <th class="text-center text-nowrap">产品描述 (简体版本) </th>
            <th class="text-center text-nowrap">價格 (HKD)</th>
            <th class="text-center text-nowrap">折後價 (HKD)</th>
            <th class="text-center text-nowrap">庫存</th>
            <th class="text-center text-nowrap">創建日期</th>
            <th class="text-center text-nowrap">狀態</th>
            <th class="text-center text-nowrap">操作</th>
          </tr>
        </thead>
        <template v-if="isLoading">
            <tr v-for="n of 10" :key="n" class="animate-pulse">
              <td v-for="header of 14" :key="header">
                <div
                  class="align-middle text-center text-nowrap py-3 rounded-pill"
                  style="background-color: rgb(229, 231, 235)"
                >
                </div>
              </td>
            </tr>
          </template>
        <tr v-else-if="!paginatedData.length">
            <td
              :colspan="9"
              class="align-middle py-4 text-left text-nowrap"
            >
              沒有找到任何結果
            </td>
          </tr>
        <draggable v-else tag="tbody" v-model="paginatedData" :list="paginatedData" @end="handleDragEnd">
          <tr v-for="item in paginatedData" :key="item.id" :data-id="item.id"  :class="{ 'grabbing': dragging, 'grab': !dragging }">
            <td class="align-middle text-center text-nowrap">
              <div class="d-flex align-items-center"><IconSortingOrder style="height: 18px; width:18px;"/></div>
            </td>
            <td class="align-middle text-center text-nowrap">
              {{item.sortingOrder}}
            </td>
            <td class="align-middle text-center text-nowrap">
              {{item.posId}}
            </td>
            <td class="align-middle text-center">
              <img width="75" class="img-fluid rounded-sm shadow-sm" v-if="item.imageUrl" v-bind:src="item.imageUrl[0]" />
            </td>
            <td class="align-middle text-center text-nowrap">{{item.nameZhHk}}</td>
            <td class="align-middle text-center text-nowrap">{{item.nameZhCn}}</td>
            <td class="align-middle text-center">
              <div class="text-truncate-3" v-html="item.descriptionZhHk"></div>
            </td>
            <td class="align-middle text-center">
              <div class="text-truncate-3" v-html="item.descriptionZhCn"></div>
            </td>
            <td class="align-middle text-center text-nowrap">{{item.price}}</td>
            <td class="align-middle text-center text-nowrap">{{!item.discountPercentage ? item.price : (item.price / 100 * ( 100 - item.discountPercentage )).toFixed(2)}}</td>
            <td class="align-middle text-center text-nowrap">{{item.inventory}}</td>
            <td class="align-middle text-center text-nowrap">{{item.createdDate | formatDate}}</td>
            <td class="align-middle text-center text-nowrap">
              <template>
                <div v-if="item.active == true" class="text-success">啟用</div>
                <div v-else-if="item.active == false" class="text-danger">停用</div>
              </template>
            </td>
            <td class="align-middle text-center text-nowrap">
              <b-dropdown variant="link" toggle-class="text-white text-decoration-none" no-caret>
                <template #button-content>
                  <b-icon icon="three-dots" font-scale="1.5" class="text-dark"></b-icon>
                </template>

                <b-dropdown-item
                  :to="'/product/'+item.id+'/edit'"
                >
                  編輯
                </b-dropdown-item>
                <b-dropdown-item
                  :to="'/product/'+item.id+'/inventory'"
                >
                存貨
                </b-dropdown-item>
                <b-dropdown-item @click="showSortingModal(item)">排序順序</b-dropdown-item>
                <b-dropdown-item
                  :disabled="item.active == false"
                  v-on:click="handleDeactivate(item.id)"
                >
                  停用
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="item.active == true"
                  v-on:click="handleActivate(item.id)"
                >
                  啟用
                </b-dropdown-item>
                <b-dropdown-item v-on:click="handleDelete(item.id)">刪除</b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </draggable>
      </table>
    </div>
    <b-modal
      id="setSortingOrder"
      centered
      no-close-on-backdrop
      hide-footer
      hide-header
      size="lg"
    >
      <b-form class="p-3" @submit.prevent="submitChangeSorting( +selectedPro.sortingOrder, +selectedSortOrder)">
        <h5 class="text-brown mb-4">更改產品的排序順序</h5>
        <b-row class="mb-3" v-if="!!this.selectedPro">
        <b-col sm="6" lg="4">
          <div v-if="this.selectedPro.imageUrl.length" class="d-flex justify-content-center align-items-center" style="height: 160px; width: 100%;">
             <img :src="this.selectedPro.imageUrl[0]" class="img-fluid rounded-sm shadow-sm" style="object-fit: cover; width: 100%; height: 100%;">
          </div>
        </b-col>
        <b-col sm="6" lg="4" class="align-self-center">
          <p><span>產品名稱 : </span>{{ this.selectedPro.nameZhHk }}</p>
          <p><span>產品系列 : </span> {{ this.selectedPro.posId }}</p>
          <p><span>價格 : </span> HKD {{ this.selectedPro.price }}</p>
        </b-col>
      </b-row>
        <b-form-input v-model="selectedSortOrder" type="number"></b-form-input>
        <div class="d-flex mt-4 align-items-center justify-content-end">
          <b-button
            @click="hideSortingModal()"
            class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4"
            >關閉</b-button
          >
          <b-button
            :disabled="!selectedSortOrder"
            type="submit"
            variant="primary"
            class="rounded-10px px-3 px-lg-5"
            >保存</b-button
          >
        </div>
      </b-form>
    </b-modal>
    <Pagination
      v-if="filterProduct.length"
      v-model="filters.page"
      :list-data="filterProduct"
      :limit="filters.size"
    />

  </b-container>
</template>

<script>
import {
  deactivateProduct,
  activateProduct,
  deleteProduct,
  updateSortingOrderForProducts,
  getAllProducts
} from '@/services/product.service'
import draggable from 'vuedraggable'
import IconSortingOrder from '../../components/icons/IconSortingOrder.vue'
import Pagination from '../../components/Pagination.vue'
import { filterByDate } from '../../utils'

export default {
  name: 'Product',

  data: () => ({
    filters: {
      name: '',
      start: '',
      end: '',
      size: 10,
      page: 1
    },
    products: [],
    dragging: false,
    draggingId: null,
    selectedSortOrder: null,
    selectedPro: null,
    isLoading: false
  }),
  components: {
    draggable,
    IconSortingOrder,
    Pagination
  },
  mounted () {
    this.getAll()
  },
  computed: {
    filterProduct () {
      return this.products.filter((item) =>
        this.filters.name.length ? item?.nameZhHk?.toLowerCase()
          ?.replace(/\s+/g, '')
          .includes(this.filters.name.toLowerCase().replace(/\s+/g, '')) ||
          item?.nameZhCn?.toLowerCase()
            .replace(/\s+/g, '')
            .includes(this.filters.name.toLowerCase().replace(/\s+/g, '')) ||
          item?.posId?.toLowerCase()
            .replace(/\s+/g, '')
            .includes(this.filters.name.toLowerCase().replace(/\s+/g, ''))
          : true
      ).filter((item) =>
        filterByDate(
          item.createdDate,
          this.filters.start,
          this.filters.end
        )
      )
    },
    paginatedData () {
      return this.filterProduct.slice(
        (this.filters.page - 1) * this.filters.size,
        this.filters.page * this.filters.size
      )
    }
  },
  watch: {
    filterProduct () {
      this.filters.page = 1
    }
  },
  methods: {
    async getAll () {
      this.isLoading = true
      const res = await getAllProducts()
      this.products = res.data.data
      this.isLoading = false
    },
    handleDeactivate (id) {
      deactivateProduct(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '產品推廣', '停用成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    handleActivate (id) {
      activateProduct(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '產品推廣', '啟用成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    handleDelete (id) {
      deleteProduct(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '產品推廣', '刪除成功')
          } else {
            this.makeToast('danger', '產品推廣', res.data.message)
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    async handleDragEnd () {
      const start = (this.filters.page - 1) * this.filters.size
      this.products.splice(start, this.filters.size, ...this.paginatedData)
      const sortedData = this.products.map((item, idx) =>
        ({ productId: item.id, sortingOrder: idx + 1 }))
      await updateSortingOrderForProducts(sortedData)
      const res = await getAllProducts()
      this.products = res.data.data
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    showSortingModal (item) {
      this.selectedPro = item
      this.selectedSortOrder = item.sortingOrder
      this.$bvModal.show('setSortingOrder')
    },
    hideSortingModal () {
      this.$bvModal.hide('setSortingOrder')
      this.selectedPro = null
    },
    async submitChangeSorting (currentOrder, newOrder) {
      const items = this.products
      const itemToUpdate = items.find(item => item.id === this.selectedPro.id)
      if (!itemToUpdate) return items

      itemToUpdate.sortingOrder = newOrder

      const updatedItems = items.map(item => {
        if (item.id !== itemToUpdate.id) {
          if (item.sortingOrder > currentOrder && item.sortingOrder <= newOrder) {
            return { productId: item.id, sortingOrder: item.sortingOrder - 1 }
          } else if (item.sortingOrder < currentOrder && item.sortingOrder >= newOrder) {
            return { productId: item.id, sortingOrder: item.sortingOrder + 1 }
          }
          return { productId: item.id, sortingOrder: item.sortingOrder }
        }
        return { productId: itemToUpdate.id, sortingOrder: item.sortingOrder }
      })
      await updateSortingOrderForProducts(updatedItems)
      this.getAll()
      this.hideSortingModal()
    }

  }
}
</script>
<style scoped>
.grab {
  cursor: move;
}

.grabbing {
  cursor: move;
}
</style>
